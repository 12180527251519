// Content Actions can be defined here
export const CHECK_IN_USE_ACTION = "check_in_use";
export const EDIT_ACTION = "edit";
export const MOVE_ACTION = "move";
export const DELETE_ACTION = "delete";
export const REFRESH_ACTION = "refresh";
export const RESTORE_ACTION = "restore";
export const PUBLISH_ACTION = "publish";
export const DUPLICATE_ACTION = "duplicate";
export const EXPORT_ACTION = "export";
